import { Box, Typography, useTheme} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";


const Invoices = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const columns = [
        { field: "id", headerName: "ID"},
        { field: "name", headerName:"Name", flex:1, cellClassName: "name-column--cell"},
        { field: "phone", headerName:"Phone Number", flex: 1},
        { field: "email", headerName:"Email", flex: 1},
        { field: "cost", headerName:"Cost", flex: 1, renderCell:(params)=>(<Typography color={colors.greenAccent[500]} >${params.row.cost}</Typography>)},
        { field: "date", headerName:"Date", flex: 1},
    ]

  return (
    <Box m="20px">
    <Header
      title="INVOICES"
      subtitle="List of Invoice Balances"
    />
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`
        }
      }}
    >
      <DataGrid
        checkboxSelection
        className="contact__datagrid"
        rows={mockDataInvoices}
        columns={columns}
        sx={{
            "& .MuiDataGrid-row:hover": {
                backgroundColor: colors.greenAccent[600]
                // color: "red"
            },
            minWidth: "800px",
            overflowX: "scroll"
        }}
      />
    </Box>
  </Box>
  )
}

export default Invoices