import { Outlet } from "react-router-dom";

const ExtLayout = () => {
  return (
<>
    <Outlet/>
</>
  )
}

export default ExtLayout