import { Box} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";

import Header from "../../components/Header";
import { useTheme } from "@mui/material";

const Contacts = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const columns = [
        { field: "id", headerName: "ID", flex: 0.5},
        { field: "refisterId", headerName: "register ID"},
        { field: "name", headerName:"Name", flex:1, cellClassName: "name-column--cell"},
        { field: "age", headerName:"Age", type: "number", headerAlign: "left", align:"left"},
        { field: "phone", headerName:"Phone Number", flex: 1},
        { field: "email", headerName:"Email", flex: 1},
        { field: "adress", headerName:"Adress", flex: 1},
        { field: "city", headerName:"City", flex: 1},
        { field: "zipCode", headerName:"ZipCode", flex: 1},
    ]

  return (
    <Box m="20px">
    <Header
      title="CONTACTS"
      subtitle="List of Contacts for Future Reference"
    />
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      <DataGrid
      className="contact__datagrid"
        rows={mockDataContacts}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
             sx={{
                    "& .MuiDataGrid-row:hover": {
                        backgroundColor: colors.greenAccent[600]
                        // color: "red"
                    },
                    minWidth: "800px",
                    overflowX: "scroll"
                }}
      />
    </Box>
  </Box>
  )
}

export default Contacts